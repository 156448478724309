import Vue from "vue";
import Vuex from "vuex";
import netConstants from "../net/netConstants";
import request from "../utils/request";
import { REGION } from "../utils/region";
Vue.use(Vuex);
// const PROJECT_CODE = "XM2403CF951AC9DT";
// const PROJECT_CODE = "XM2209B1FB92F6DT"; // 青春家
const PROJECT_CODE = "XM2209B2EFF492DT"; // 美的电梯产品公司

export default new Vuex.Store({
  state: {
    projectInfo: {},
    faultLog: {},
    warnLog: {},
    faultPagination: {
      pageIndex: 1,
      pageSize: 5,
      current: 1,
      total: 1000,
    },
    warnPagination: {
      pageIndex: 1,
      pageSize: 5,
      current: 1,
      total: 1000,
    },
    statistics: {},
    statusStatistics: {},
    eventStatistics: {},
    liftList: [],
    recentTop: [],
    loadingMap: {
      projectInfo: true,
      faultLog: true,
      warnLog: true,
      liftList: true,
      statistics: true,
      statusStatistics: true,
      eventStatistics: true,
      recentTop: true,
      weather: true,
      eventLogYearPerMonth: true,
      warnLogYearPerMonth: true,
      faultRate: true,
    },
    weather: {},
    eventLogYearPerMonth: [],
    warnLogYearPerMonth: [],
    faultRate: [],
    faultRateParams: {
      dateType: 2,
      dimension: "lift",
      timeSegmentType: 1,
    },
    alarm: [],
    modalShow: false,
    modalContent: {
      title: "",
      code: "",
      event: "",
      time: "",
    },
  },
  getters: {
    loading: (state) => {
      return !Object.values(state.loadingMap).every((value) => value === false);
    },
  },
  mutations: {
    SET_PROJECT_INFO(state, data) {
      state.projectInfo = data;
    },
    SET_FAULT_LOG(state, data) {
      state.faultLog = data;
    },
    SET_WARN_LOG(state, data) {
      state.warnLog = data;
    },
    SET_FAULT_LOG_PAGINATION(state, data) {
      state.faultPagination = data;
    },
    SET_WARN_LOG_PAGINATION(state, data) {
      state.warnPagination = data;
    },
    SET_LOADING_FALSE(state, p) {
      state.loadingMap[p] = false;
    },
    SET_LIFT_LIST(state, data) {
      state.liftList = data;
    },
    SET_LIFT(state, data) {
      const map = this.state.liftList.map((item) => {
        if (item.liftCode === data.liftCode && data) {
          if (!data.properties) {
            data.properties = [];
          }
          if (!data.realData) {
            data.realData = [];
          }
          item.properties = [...data.properties, ...data.realData];
        }
        return item;
      });
      state.liftList = map;
    },
    SET_STATISTICS(state, data) {
      state.statistics = data;
    },
    SET_STATUS_STATISTICS(state, data) {
      state.statusStatistics = data;
    },
    SET_EVENT_STATISTICS(state, data) {
      state.eventStatistics = data;
    },
    SET_RECENT_TOP(state, data) {
      state.recentTop = data;
    },
    SET_WEATHER(state, data) {
      state.weather = data;
    },
    SET_EVENT_LOG_YEAR_PER_MONTH(state, data) {
      state.eventLogYearPerMonth = data;
    },
    SET_WARN_LOG_YEAR_PER_MONTH(state, data) {
      state.warnLogYearPerMonth = data;
    },
    SET_FAULT_RATE(state, data) {
      state.faultRate = data;
    },
    SET_FAULT_RATE_PARAMS(state, data) {
      state.faultRateParams = data;
    },
    SET_ALARM(state, data) {
      const arr = [...state.alarm, ...data];
      state.alarm = arr.filter((item) => item);
    },
    DELETE_ALARM(state, eventCode) {
      const arr = state.alarm.map((item) => {
        if (!item) {
          return undefined;
        }
        if (item.eventCode === eventCode) {
          return undefined;
        } else {
          return item;
        }
      });
      state.alarm = arr;
    },
    SET_MODAL(state, data) {
      state.modalShow = data;
    },
    SET_MODAL_CONTENT(state, data) {
      state.modalContent = data;
    },
  },
  actions: {
    openModal({ commit }, obj) {
      commit("SET_MODAL", true);
      commit("SET_MODAL_CONTENT", obj);
    },
    closeModal({ commit }) {
      commit("SET_MODAL", false);
    },
    fetchProjectData({ commit }) {
      request
        .get(netConstants.projectInfo + `/${PROJECT_CODE}`)
        .then((res) => {
          commit("SET_LOADING_FALSE", "projectInfo");
          const data = res.data.data;
          commit("SET_PROJECT_INFO", {
            ...data,
            countyName: REGION[data.countyCode],
          });
          // 故障 list
          this.dispatch("fetchFaultLog", {
            projectName: data.projectName,
            pageIndex: 1,
            pageSize: 5,
            current: 1,
          });
          // 预警 list
          this.dispatch("fetchWarnLog", {
            projectName: data.projectName,
            pageIndex: 1,
            pageSize: 5,
            current: 1,
          });

          //  电梯运行统计
          this.dispatch("fetchStatistics", {
            projectName: data.projectName,
          });

          // 电梯总览
          this.dispatch("fetchStatusStatistics", {
            projectName: data.projectName,
          });
          // 事件管理
          this.dispatch("fetchEventStatistics", {
            projectName: data.projectName,
          });
          // top5
          this.dispatch("fetchRecentTop", {
            projectName: data.projectName,
          });
          // 事件趋势
          this.dispatch("fetchEventLogYearPerMonth", {
            projectName: data.projectName,
          });
          // 预警趋势
          this.dispatch("fetchWarnLogYearPerMonth", {
            projectName: data.projectName,
          });
          // realData
          this.dispatch("fetchLiftRealData", {
            projectName: data.projectName,
          });
          // 故障率
          this.dispatch("fetchFaultRate", {
            projectName: data.projectName,
          });
          // 天气
          this.dispatch("fetchWeather", {
            acCode: data.countyCode,
          });
        })
        .catch((error) => {
          commit("SET_LOADING_FALSE", "projectInfo");

          console.error("Error fetching data:", error);
        });
    },
    fetchFaultLog({ commit }, { projectName, pageIndex }) {
      commit("SET_FAULT_LOG_PAGINATION", {
        pageIndex: pageIndex,
      });
      request
        .get(netConstants.faultLog, {
          pageIndex,
          pageSize: 5,
          current: pageIndex,
          // projectName: "B栋复建货梯项目",
          projectName,
          // projectCode:PROJECT_CODE,
        })
        .then((res) => {
          const data = res.data;
          commit("SET_LOADING_FALSE", "faultLog");

          commit("SET_FAULT_LOG", data);
        })
        .catch((error) => {
          commit("SET_LOADING_FALSE", "faultLog");
          console.error("Error fetching data:", error);
        });
    },
    fetchWarnLog({ commit }, { projectName, pageIndex }) {
      commit("SET_WARN_LOG_PAGINATION", {
        pageIndex: pageIndex,
      });
      request
        .get(netConstants.warnLog, {
          pageIndex,
          pageSize: 5,
          current: pageIndex,
          // projectName: "B栋复建货梯项目",
          projectName,
          // projectCode:PROJECT_CODE,
        })
        .then((res) => {
          const data = res.data;
          commit("SET_LOADING_FALSE", "warnLog");

          commit("SET_WARN_LOG", data);
        })
        .catch((error) => {
          commit("SET_LOADING_FALSE", "warnLog");
          console.error("Error fetching data:", error);
        });
    },
    fetchFaultLogAlarm({ commit }, { projectName }) {
      request
        .get(netConstants.faultLog, {
          pageIndex: 1,
          pageSize: 5,
          current: 1,
          // projectName: "B栋复建货梯项目",
          projectName,
          // removeStatus: false,
          // projectCode:PROJECT_CODE,
        })
        .then((res) => {
          const data = res.data.rows || [];
          const localData = JSON.parse(localStorage.getItem("alarm") || "[]");

          const eventCodeInLocalData = new Set(
            localData.map((item) => item.eventCode)
          );
          const uniqueInData = data.filter(
            (item) => !eventCodeInLocalData.has(item.eventCode)
          );
          if (
            (localData.length === 0 && data.length !== 0) ||
            uniqueInData.length !== 0
          ) {
            localStorage.setItem("alarm", JSON.stringify(uniqueInData || "[]"));
            commit("SET_ALARM", uniqueInData);
          }
          // commit("SET_LOADING_FALSE", "faultLog");

          // commit("SET_FAULT_LOG", data);
        })
        .catch((error) => {
          // commit("SET_LOADING_FALSE", "faultLog");
          console.error("Error fetching data:", error);
        });
    },
    deleteAlarm({ commit }, eventCode) {
      commit("DELETE_ALARM", eventCode);
    },
    fetchLiftList({ commit }) {
      request
        .get(netConstants.liftList, {
          pageIndex: 1,
          pageSize: 1000,
          current: 1,
          projectCode: PROJECT_CODE,
          removeStatus: "true",
        })
        .then((res) => {
          // 上线后删除 slice(0,7)
          const data = res.data.rows.slice(0, 7);
          commit("SET_LIFT_LIST", data);
          commit("SET_LOADING_FALSE", "liftList");
        })
        .catch((error) => {
          commit("SET_LOADING_FALSE", "liftList");
          console.error("Error fetching data:", error);
        });
    },
    fetchLiftDetail({ commit }, liftCode) {
      request
        .get(netConstants.liftDetail + `/${liftCode}`)
        .then((res) => {
          const data = res.data.data;

          commit("SET_LIFT", { ...data, liftCode });
          // commit("SET_LOADING_FALSE", false);
        })
        .catch((error) => {
          // commit("SET_LOADING_FALSE", false);
          console.error("Error fetching data:", error);
        });
    },
    fetchLiftStatusStatisTics({ commit }, { liftCode }) {
      request
        .get(netConstants.liftStatusStatistics, {
          liftNameOrCode: liftCode,
        })
        .then((res) => {
          console.log(res, commit);
          // const data = res.data.data;

          // commit("SET_STATUS_STATISTICS", data);
          // commit("SET_LOADING_FALSE", "statusStatistics");
        })
        .catch((error) => {
          console.log(error);
          // commit("SET_LOADING_FALSE", "statusStatistics");
          // console.error("Error fetching data:", error);
        });
    },
    fetchStatistics({ commit }, { projectName }) {
      request
        .get(netConstants.liftStatistics, {
          projectName,
        })
        .then((res) => {
          const data = res.data.data;

          commit("SET_STATISTICS", data);
          commit("SET_LOADING_FALSE", "statistics");
        })
        .catch((error) => {
          commit("SET_LOADING_FALSE", "statistics");
          console.error("Error fetching data:", error);
        });
    },
    fetchStatusStatistics({ commit }, { projectName }) {
      request
        .get(netConstants.liftStatusStatistics, {
          projectName,
        })
        .then((res) => {
          const data = res.data.data;

          commit("SET_STATUS_STATISTICS", data);
          commit("SET_LOADING_FALSE", "statusStatistics");
        })
        .catch((error) => {
          commit("SET_LOADING_FALSE", "statusStatistics");
          console.error("Error fetching data:", error);
        });
    },
    // fetchStatistics({ commit }, { projectName }) {
    //   request
    //     .get(netConstants.liftStatistics, {
    //       projectName,
    //     })
    //     .then((res) => {
    //       const data = res.data.data;

    //       commit("SET_STATISTICS", data);
    //       commit("SET_LOADING_FALSE", "statistics");
    //     })
    //     .catch((error) => {
    //       commit("SET_LOADING_FALSE", "statistics");
    //       console.error("Error fetching data:", error);
    //     });
    // },
    fetchLiftRealData({ commit }, { projectName }) {
      request
        .get(netConstants.listRealData, {
          projectName,
          pageIndex: 1,
          pageSize: 1000,
          current: 1,
        })
        .then((res) => {
          // 上线后删除 slice(0,7)
          const data = res.data.data.slice(0, 7);
          commit("SET_LIFT_LIST", data);
          commit("SET_LOADING_FALSE", "liftList");
        })
        .catch((error) => {
          commit("SET_LOADING_FALSE", "liftList");
          console.error("Error fetching data:", error);
        });
    },
    fetchEventStatistics({ commit }, { projectName }) {
      request
        .get(netConstants.eventStatistics, {
          projectName,
        })
        .then((res) => {
          const data = res.data.data;

          commit("SET_EVENT_STATISTICS", data);
          commit("SET_LOADING_FALSE", "eventStatistics");
        })
        .catch((error) => {
          commit("SET_LOADING_FALSE", "eventStatistics");
          console.error("Error fetching data:", error);
        });
    },
    fetchRecentTop({ commit }, { projectName }) {
      request
        .get(netConstants.recentTop, {
          projectName,
        })
        .then((res) => {
          const data = res.data.data;

          commit("SET_RECENT_TOP", data);
          commit("SET_LOADING_FALSE", "recentTop");
        })
        .catch((error) => {
          commit("SET_LOADING_FALSE", "recentTop");
          console.error("Error fetching data:", error);
        });
    },
    fetchWeather({ commit }, { acCode }) {
      request
        .get(netConstants.weather + `/${acCode}`)
        .then((res) => {
          const data = res.data.data;

          commit("SET_WEATHER", data);
          commit("SET_LOADING_FALSE", "weather");
        })
        .catch((error) => {
          commit("SET_LOADING_FALSE", "weather");
          console.error("Error fetching data:", error);
        });
    },
    fetchEventLogYearPerMonth({ commit }, { projectName }) {
      request
        .get(netConstants.eventLogYearPerMonth, {
          projectName,
        })
        .then((res) => {
          const data = res.data.data;

          commit("SET_EVENT_LOG_YEAR_PER_MONTH", data);
          commit("SET_LOADING_FALSE", "eventLogYearPerMonth");
        })
        .catch((error) => {
          commit("SET_LOADING_FALSE", "eventLogYearPerMonth");
          console.error("Error fetching data:", error);
        });
    },
    fetchWarnLogYearPerMonth({ commit }, { projectName }) {
      request
        .get(netConstants.warnLogYearPerMonth, {
          projectName,
        })
        .then((res) => {
          const data = res.data.data;

          commit("SET_WARN_LOG_YEAR_PER_MONTH", data);
          commit("SET_LOADING_FALSE", "warnLogYearPerMonth");
        })
        .catch((error) => {
          commit("SET_LOADING_FALSE", "warnLogYearPerMonth");
          console.error("Error fetching data:", error);
        });
    },
    fetchFaultRate(
      { commit },
      { projectName, dateType, dimension, timeSegmentType }
    ) {
      const defaultP = {
        dateType: 2,
        dimension: "lift",
        timeSegmentType: 1,
      };
      const o = {
        dateType,
        dimension,
        timeSegmentType,
      };
      const params = dateType ? o : defaultP;
      if (dateType) {
        commit("SET_FAULT_RATE_PARAMS", o);
      }
      request
        .get(netConstants.faultLogRate, {
          projectName,
          ...params,
        })
        .then((res) => {
          const data = res.data.data.slice(0, 7);
          // const data = res.data.rows.slice(0, 7);
          commit("SET_FAULT_RATE", data);
          commit("SET_LOADING_FALSE", "faultRate");
        })
        .catch((error) => {
          commit("SET_LOADING_FALSE", "faultRate");
          console.error("Error fetching data:", error);
        });
    },
  },
  modules: {},
});
